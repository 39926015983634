<template>
  <section class="add-amenities-slider">
    <div class="row flex-grow">

      <Loader :loading="showLoader" />
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"> Add Banner</h4>
            <form class="forms-sample" @submit.prevent="addSlider">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="slider.title" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Banner Image</label>
                    <!-- <sub>Image width 2000px</sub> -->
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="bannerBigUpload">
                      <div class="dropzone-custom-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                          fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round" class="feather feather-upload" color="">
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="17 8 12 3 7 8"></polyline>
                          <line x1="12" y1="3" x2="12" y2="15"></line>
                        </svg>
                        <div class="subtitle">
                          <span> Drop File or </span>
                          <span style="color: #eb2027"> Browse</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
const imageURL = process.env.VUE_APP_BASE_URL;
import { sliderApi } from "../api";
export default {
  name: 'add-avant-garde-banner',
  components: {
    vueDropzone: vue2Dropzone, Loader,
  },
  data() {
    return {
      imageURL,
      showLoader: false,
      slider: {
        title: "",
        banner: "",
        type: "avantGarde"
      },
      thumb: {
        url: `${imageURL}/upload`,
        maxFilesize: 1,
      },
    };
  },
  methods: {

    bannerBigUpload(file, response) {
      this.slider.banner = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async addSlider() {
      this.showLoader = true;
      const data = await sliderApi.addSlider({
        ...this.slider,
      });
      this.showLoader = false;
      this.$router.push("/avant-garde-banner");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },
  }
}
</script>